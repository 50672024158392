main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: 'Pixelify Sans';
}

#password {
  padding: 10px;
  border: transparent;
  border-bottom: 1px solid #000;
  width: 50%;
}

#submit {
  padding: 10px;
  border: transparent;
  background-color: #000;
  color: #fff;
  cursor: pointer;
}
