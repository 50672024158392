.RadioAndLabel {
	display: flex;
	margin: 20px;
}

.RadioAndLabel label {
	margin-left: 10px;
}

.RadioAndLabel > * {
	float: left;
}
