.section1 {
	pointer-events: none;
	border: 0px solid #000;
	border-radius: 50px;
	width: 80%;
	margin: 10vh auto;
	background-color: #f3f3ea;
	padding: 40px;
}

.section {
	border: 0px solid #000;
	border-radius: 50px;
	width: 80%;
	margin: 10vh auto;
	background-color: #f3f3ea;
	padding: 40px;
}

.sectionHeader {
	position: relative;
	padding-left: 20px;
}

.sectionHeader h3 {
	margin: 0px;
	font-weight: 100;
	font-size: 1em;
}

.underSectionSeparator {
	margin-top: 40px;
	padding-top: 20px;
	margin-bottom: 20px;
	border-top: 2px solid #d7d7d7;
	text-align: center;
	font-size: 1.2em;
	color: #0a1f3c;
	font-weight: bold;
}

#lock {
	position: absolute;
	top: 0px;
	left: -30px;
	width: 30px;
}

.pictograms {
	display: flex;
	justify-content: space-evenly;
	margin-bottom: 20px;
	margin-top: 40px;
}

#suppButton {
	transition: all 0.2s;
}

#suppButton:hover {
	background-color: #ec2a28;
	transform: scale(0.95);
}

.formContent {
	margin: 0px 20px;
}

.fieldLine {
	display: flex;
	justify-content: space-between;
	align-items: top;
	gap: 20px;
}

.fieldLine > * {
	flex: auto;
	width: 100%;
}

.fitContentLine {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
}

.filesContent {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 40px;
}

@media (max-width: 500px) {
	.section {
		margin: 3vh auto;
	}

	.fieldLine {
		flex-direction: column;
		gap: 10px;
	}
}

@media (max-width: 650px) {
	.filesContent {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
}
