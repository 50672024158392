.LabelAndTextInput {
	margin: 20px 0px;
}

.LabelAndTextInput input {
	border: transparent;
	border-bottom: 1px solid #000;
	width: 100%;
	padding: 4px 0px 4px 0px;
	background-color: transparent;
}
