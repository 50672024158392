.picto1 {
	border: 3px solid #0a1f3c;
	border-radius: 20px;
	width: 20%;
	min-width: 100px;
	transition: 0.2s;
}

.picto1 img {
	margin: 10px;
}

.picto1 p {
	width: 100%;
	text-align: center;
}

.picto1:hover {
	background-color: #ffffff;
	border: 3px double white;
	color: #0a1f3c;
	transform: scale(0.95);
}

.picto1.active img {
	filter: invert(1) grayscale(100%);
}

.picto1.active {
	background-color: #0a1f3c;
	border: 3px solid #0a1f3c;
	color: white;
	transform: scale(0.95);
}

.picto0 {
	border: 3px solid #0a1f3c;
	border-radius: 20px;
	width: 20%;
	min-width: 100px;
	transform: scale(0.95);
	background-color: #0a1f3c;
	color: white;
	transform: scale(0.95);
}

.picto0 img {
	margin: 10px;
	filter: invert(1) grayscale(100%);
}

.picto0 p {
	width: 100%;
	text-align: center;
}
