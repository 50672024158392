.fileBox {
	position: relative;
	border: 1px solid #0a1f3c;
	border-radius: 20px;
	width: 25vw;
	min-width: 90%;
	padding: 10px;
	margin: 0 auto;
}

.fileHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.fileHeader img {
	width: 50px;
	height: 50px;
}

.fileContent {
	text-align: center;
}

.fileContent img {
	max-width: 100%;
}

.filePlaceholder {
	display: block;
	font-size: 10rem;
}

.input-file-upload {
	display: none;
}

.fileContent .buttonIconAndText {
	margin: auto;
}

.fileRender {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 10px;
}

.fileRender > * {
	word-break: break-all;
}

#label-file-upload.drag-active {
	background-color: #999999;
}

#drag-file-element {
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 1rem;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
}

@media (max-width: 650px) {
	.fileRender {
		display: none;
	}
}
