.buttonContent {
	display: flex;
	align-items: center;
}

.buttonIconAndText {
	width: fit-content;
}

.buttonIconAndText img {
	height: 17px;
	margin-right: 12px;
}

.buttonIconAndText.invert img {
	margin-right: 0;
	margin-left: 12px;
}

.buttonIconAndText.send {
	font-size: 1.4em;
}
