#form {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	padding-bottom: 96px;
}

.readonly {
	pointer-events: none;
}

.header {
	align-items: center;
	justify-content: center;
	text-align: center;
	margin-bottom: 8vh;
}

#logo {
	background: url('@assets/form/logo le ficaneur-03.svg') center center;
	background-size: contain;
	background-repeat: no-repeat;
	width: 100%;
	min-width: 250px;
	height: 88px;
}

.header h1 {
	font-size: 5em;
	font-weight: 400;
	line-height: 1.2em;
	margin-bottom: 0px !important;
}

.header h2 {
	border-top: 1px solid black;
	text-align: right;
	padding-top: 10px;
	width: 80%;
	margin: auto;
}

#description {
	font-size: 1.2em;
	font-weight: 800;
	margin-bottom: -8vh;
}

#modalBody h1 {
	font-size: 1.5em;
	font-weight: 800;
	text-align: center;
	margin: auto;
	width: 50%;
}

#modalBody img {
	width: 100%;
}

#modalBody p {
	font-size: 1em;
	font-weight: 100;
	text-align: center;
	color: #0a1e3c;
}

#modalButtons {
	display: flex;
	justify-content: center;
}

.error {
	display: block;
	color: red;
	font-size: 12px;
}

.text-center {
	text-align: center;
}

.flex {
	display: flex;
}

@media (max-width: 715px) {
	.header {
		margin-bottom: 4vh;
	}
	.header h1 {
		font-size: 3em;
	}
}
