.SelectBoxAndLabel {
  margin: 20px 0px;
}

.SelectBoxAndLabel select {
  background-color: transparent;
  border: transparent;
  border-bottom: 1px solid #000;
  width: 100%;
  padding: 4px 0px 4px 0px;
}
