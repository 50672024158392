.button {
	background-color: #0a1f3c;
	padding: 8px 24px 8px 24px;
	border-radius: 24px;
	border-width: 0px;
	color: white;
	transition: 0.2s;
}

.button:hover {
	color: #f3f3ea;
	background-color: #030d1a;
	transform: scale(0.97);
}
