.modalBackground {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 500;
}

.modalForeground {
	background-color: white;
	padding: 20px;
	border-radius: 50px;
	width: 50%;
}
